.floating-button {
  position: fixed;
  bottom: 50%;
  left: 20px;
  z-index: 99;
}

.floating-button a {
  background-color: #fd7e14;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}